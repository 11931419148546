




import { Component, Vue } from 'vue-property-decorator';
import IndicadorBarraComponent from '@/components/IndicadorBarraComponent.vue'; // @ is an alias to /src

@Component({
  components: {
    IndicadorBarraComponent,
  },
})
export default class IndicadorPorRol extends Vue {
  
}
