import Axios, { AxiosResponse } from 'axios'
import { IIndicador, ICliente, IKardexCliente, ISaldoCliente, IPedidoCliente, IPrecioCliente, IComodatoCliente } from '@/store/types/consultas'
import { IRespuestaApi } from '@/store/types/comun'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'


@Module({ 
	name: 'consultas',
	namespaced: true, 
})
export default class Consultas extends VuexModule {

	local_storage_name = 'bdr-consultas'

	fecha = new Date()
		// siempre tendriamos un data de tipo "IContextoState" para serializar 
	indicadores: Array<IIndicador> = [] 
	clientes: Array<ICliente> = []
	cliente: IKardexCliente = {cvecli: 0}
	saldos: Array<ISaldoCliente> = [] 
	pedidos: Array<IPedidoCliente> = []
	precios: Array<IPrecioCliente> = []
	comodatos: Array<IComodatoCliente> = []
	error = ''

	get GET_INDICADORES(): Array<IIndicador> {
		return this.indicadores;
	}

	get GET_CLIENTES(): Array<ICliente> {
		return this.clientes;
	}

	get GET_CLIENTE(): IKardexCliente {
		return this.cliente;
	}

	get GET_SALDOS(): Array<ISaldoCliente> {
		return this.saldos
	}

	get GET_PEDIDOS(): Array<IPedidoCliente> {
		return this.pedidos
	}

	get GET_PRECIOS(): Array<IPrecioCliente> {
		return this.precios
	}

	get GET_COMODATOS(): Array<IComodatoCliente> {
		return this.comodatos
	}

	get GET_LOCALSTORAGE_NAME(): string {
		return this.local_storage_name
	}

	@Mutation
	SET_ERROR(error: string) {
		this.error = error
	}

	@Mutation
	SET_FECHA(fecha: Date) {
		this.fecha = fecha
	}

	@Mutation
	SET_INDICADORES(data: Array<IIndicador>) {
		this.indicadores = data
	}

	@Mutation
	SET_CLIENTES(data: Array<ICliente>) {
		this.clientes = data
	}

	@Mutation
	SET_CLIENTE(data: IKardexCliente) {
		this.cliente = data
	}

	@Mutation
	SET_SALDOS(data: Array<ISaldoCliente>) {
		this.saldos = data
	}

	@Mutation
	SET_PEDIDOS(data: Array<IPedidoCliente>) {
		this.pedidos = data
	}

	@Mutation
	SET_PRECIOS(data: Array<IPrecioCliente>) {
		this.precios = data
	}

	@Mutation
	SET_COMODATOS(data: Array<IComodatoCliente>) {
		this.comodatos = data
	}


	@Action
	async doGetIndicadores() {
		this.context.commit('SET_ERROR', '')
		let response = {} as AxiosResponse
		try {
			const dia = this.fecha.getDate();
			const mes = this.fecha.getMonth();
			const anio = this.fecha.getFullYear();
			const qString = 'anio=' + anio.toString() + '&mes=' + (mes+1).toString() + '&dia=' + dia.toString()
			response = await Axios.get('private/consultas/indicadores?'+ qString )
			if (response && response.data && response.data !== null && response.data.error !== true) {
				const respuesta = response.data as IRespuestaApi				
				const datos = respuesta.data
				this.context.commit('SET_INDICADORES', datos);
				this.context.commit('SET_ERROR', response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', response.data.mensaje)
			}
		}
		catch(error)  {
			if (error.response) {
				//console.log(error.response)
				this.context.commit('SET_ERROR', error.response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', 'Error al conectarse al servicio de indicadores.')
			}
		}		
	}


	@Action
	async doGetListaClientes(search: string) {
		//console.log('login out')
		this.context.commit('SET_ERROR', '')
		this.context.commit('SET_CLIENTES',  null);
		let response = {} as AxiosResponse
		try {
			response = await Axios.get('private/consultas/clientes?searchText=' + search)
			if (response && response.data && response.data !== null && response.data.error !== true) {
				const respuesta = response.data as IRespuestaApi				
				const datos = respuesta.data
				this.context.commit('SET_CLIENTES', datos);
				this.context.commit('SET_ERROR', response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', response.data.mensaje)
			}
		}
		catch(error)  {
			if (error.response) {
				//console.log(error.response)
				this.context.commit('SET_ERROR', error.response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', 'Error al conectarse al servicio de busqueda de clientes.')
			}
		}
		
	}

	@Action
	async doGetCliente(cvecli: number) {
		//console.log('login out')
		this.context.commit('SET_ERROR', '')
		let response = {} as AxiosResponse
		try {
			response = await Axios.get('private/consultas/cliente/datos?cvecli=' + cvecli.toString())
			if (response && response.data && response.data !== null && response.data.error !== true) {
				const respuesta = response.data as IRespuestaApi				
				const datos = respuesta.data
				this.context.commit('SET_CLIENTE', datos);
				this.context.commit('SET_ERROR', response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', response.data.mensaje)
			}
		}
		catch(error)  {
			if (error.response) {
				//console.log(error.response)
				this.context.commit('SET_ERROR', error.response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', 'Error al conectarse al servicio de comodatos.')
			}
		}
		
	}	

	@Action
	async doGetComodatosCliente(cvecli: number) {
		//console.log('login out')
		this.context.commit('SET_ERROR', '')
		let response = {} as AxiosResponse
		try {
			response = await Axios.get('private/consultas/cliente/comodatos?cvecli=' + cvecli.toString())
			if (response && response.data && response.data !== null && response.data.error !== true) {
				const respuesta = response.data as IRespuestaApi				
				const datos = respuesta.data
				this.context.commit('SET_COMODATOS', datos);
				this.context.commit('SET_ERROR', response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', response.data.mensaje)
			}
		}
		catch(error)  {
			if (error.response) {
				//console.log(error.response)
				this.context.commit('SET_ERROR', error.response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', 'Error al conectarse al servicio de comodatos.')
			}
		}
		
	}	

	@Action
	async doGetSaldosCliente(cvecli: number) {
		//console.log('login out')
		this.context.commit('SET_ERROR', '')
		let response = {} as AxiosResponse
		try {
			response = await Axios.get('private/consultas/cliente/saldos?cvecli=' + cvecli.toString())
			if (response && response.data && response.data !== null && response.data.error !== true) {
				const respuesta = response.data as IRespuestaApi				
				const datos = respuesta.data
				this.context.commit('SET_SALDOS', datos);
				this.context.commit('SET_ERROR', response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', response.data.mensaje)
			}
		}
		catch(error)  {
			if (error.response) {
				//console.log(error.response)
				this.context.commit('SET_ERROR', error.response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', 'Error al conectarse al servicio de saldos.')
			}
		}
		

	}	

	@Action
	async doGetPedidosCliente(cvecli: number) {
		//console.log('login out')
		this.context.commit('SET_ERROR', '')
		let response = {} as AxiosResponse
		try {
			response = await Axios.get('private/consultas/cliente/pedidos?cvecli=' + cvecli.toString())
			if (response && response.data && response.data !== null && response.data.error !== true) {
				const respuesta = response.data as IRespuestaApi				
				const datos = respuesta.data
				this.context.commit('SET_PEDIDOS', datos);
				this.context.commit('SET_ERROR', response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', response.data.mensaje)
			}
		}
		catch(error)  {
			if (error.response) {
				//console.log(error.response)
				this.context.commit('SET_ERROR', error.response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', 'Error al conectarse al servicio de pedidos de cliente.')
			}
		}
		
	}	

	@Action
	async doGetPreciosCliente(cvecli: number) {
		//console.log('login out')
		this.context.commit('SET_ERROR', '')
		let response = {} as AxiosResponse
		try {
			response = await Axios.get('private/consultas/cliente/precios?cvecli=' + cvecli.toString())
			if (response && response.data && response.data !== null && response.data.error !== true) {
				const respuesta = response.data as IRespuestaApi				
				const datos = respuesta.data
				this.context.commit('SET_PRECIOS', datos);
				this.context.commit('SET_ERROR', response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', response.data.mensaje)
			}
		}
		catch(error)  {
			if (error.response) {
				//console.log(error.response)
				this.context.commit('SET_ERROR', error.response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', 'Error al conectarse al servicio de saldos.')
			}
		}
		

	}	


}