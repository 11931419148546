

























import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { IIndicador, IChartData, IChartDataset } from '@/store/types/consultas'
import IndicadorBarraComponent from '@/components/IndicadorBarraComponent.vue'

@Component({
  computed: {
    ...mapGetters('consultas', {
      indicadores: 'GET_INDICADORES'
    })  
  },
  components: {
    IndicadorBarraComponent
  }
})
export default class HomeComponent extends Vue {
  private indicadores?: Array<IIndicador>
  private data? = [] as Array<IChartData>
  private tiempo = 60000 // tiempo es en milisegundos (10,000 son 10 segundos, 300,000 son 3 minutos)
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private polling: any
  // fecha está vinculada porque solo se hace un search general
  private fecha? = new Date()

  private options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 10,
        }
      }]
    },
  }

  doPolling() {      
      this.polling = setInterval( () => {
          this.getIndicadores()
      }, this.tiempo);
  }

  mounted() {
    this.$store.commit('consultas/SET_FECHA', this.fecha)
    this.getIndicadores()
    this.doPolling()
  }

  beforeDestroy () {
    if (this.polling) {
      clearInterval(this.polling)
    }
  }

  doSearch() {
    clearInterval(this.polling)
    this.$store.commit('consultas/SET_FECHA', this.fecha)
    this.getIndicadores()
  }

  setPollingAndDoSearchToday() {
    clearInterval(this.polling)
    this.fecha = new Date()
    this.$store.commit('consultas/SET_FECHA', this.fecha)
    this.getIndicadores()
    this.doPolling()
  }

  async getIndicadores() {
    const colorBarraBDRLocal = 'rgba(162, 42, 42, 0.7)'
    const colorBordeBDRLocal = 'rgba(162, 42, 42, 1)'

    const colorBarraBDRForaneo = 'rgba(0, 104, 0, 0.7)'
    const colorBordeBDRForaneo = 'rgba(0, 104, 0, 1)'

    const colorBarraSupervisor = 'rgba(162, 42, 42, 0.7)'
    const colorBordeSupervisor = 'rgba(162, 42, 42, 1)'

    const colorBarraBDRMercado = 'rgba(0, 104, 0, 0.7)'
    const colorBordeBDRMercado = 'rgba(0, 104, 0, 1)'
    
    await this.$store.dispatch('consultas/doGetIndicadores')
    this.data = [] as Array<IChartData>
    const ind = this.data
    let color = ''
    let border = ''

    if (this.indicadores && this.indicadores !== null) {
      this.indicadores.forEach((i: IIndicador ) => {
        if (i.clave === 'bdr_local') {
          color = colorBarraBDRLocal
          border = colorBordeBDRLocal
        } else if (i.clave === 'bdr_foraneo') {
          color = colorBarraBDRForaneo
          border = colorBordeBDRForaneo
        } else if (i.clave === 'bdr_foraneo') {
          color = colorBarraSupervisor
          border = colorBordeSupervisor
        } else if (i.clave === 'bdr_foraneo') {
          color = colorBarraBDRMercado
          border = colorBordeBDRMercado
        }

        ind.push( {
            clave: i.clave,
            labels: [i.texto1, i.texto2],
            datasets: [
              {
                label: i.titulo,              
                backgroundColor: color,
                borderColor: border,
                borderWidth: 4,              
                data: [i.valor1, i.valor2],
              } as IChartDataset
            ] 
          } as IChartData
        )      
      })
      this.data = ind
    }
  }

}
