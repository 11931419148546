import Vue from 'vue'
import Vuex from 'vuex'
import Seguridad from './modules/seguridad'
import Consultas from './modules/consultas'
import { getModule } from 'vuex-module-decorators'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		seguridad: Seguridad,
		consultas: Consultas,
	}
})

export default store

export const SeguridadModule = getModule(Seguridad, store)
export const consultasModule = getModule(Consultas, store)
