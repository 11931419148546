import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Clientes from '../views/Clientes.vue'
import KardexCliente from '../views/KardexCliente.vue'
import IndicadorPorRol from '../views/IndicadorPorRol.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			guest: true
		}
	},
	{
		path: '/logout',
		name: 'logout',
		component: Logout,
		meta: {
			requiresAuth: true
	}
	},
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/clientes',
		name: 'clientes',
		component: Clientes,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/clientes/:cvecli',
		name: 'kardex',
		component: KardexCliente,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/indicadores/por-rol/:rol',
		name: 'indicador-por-rol',
		component: IndicadorPorRol,
		meta: {
			requiresAuth: true
		}
	}
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const lsUserItem = 'bdr-seguridad'

router.beforeEach((to, from, next) => {
	// TODO: cargar del store el data actual y determinar si está logged in
	const item = localStorage.getItem(lsUserItem)
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!item) {
			next({
				path: '/login',
				params: { nextUrl: to.fullPath }
			})
		} else {

			const user = JSON.parse(item == null ? "" : item.toString())
			if (user.token) {
				// TODO: validar token? validar permisos?
				next()
			} else {
				next({
					path: '/login',
					params: { nextUrl: to.fullPath }
				})
			}
			//if (to.matched.some(record => record.meta.is_admin)) {
			//	if (user.is_admin == 1) {
			//		next()
			//	}
			//	else {
			//		next({ name: 'userboard' })
			//	}
			//} else {
			//	next()
			//}
		}
	} else if (to.matched.some((record) => record.meta.guest)) {
		if (!item || item == null) {
			next()
		}
		else {
			next({ name: 'home' })
		}
	} else {
		next()
	}
})
export default router
