




import { Component, Vue } from 'vue-property-decorator';
import LoginComponent from '@/components/LoginComponent.vue'; // @ is an alias to /src

@Component({
  components: {
    LoginComponent,
  },
})
export default class Login extends Vue {
  
}
