







import { Component, Vue } from 'vue-property-decorator';
import HeaderComponent from '@/components/HeaderComponent.vue'; // @ is an alias to /src

@Component({
  components: {
    HeaderComponent,
  },
})
export default class App extends Vue {
}
