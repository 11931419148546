





























































import { Component, Vue } from 'vue-property-decorator';
import { ILogin } from '@/store/types/seguridad';
import { mapGetters, mapActions } from 'vuex';

	@Component({
		computed: {
			...mapGetters('seguridad', {
				isLoggedIn: 'GET_IS_LOGGED_IN',
				error: 'GET_ERROR',
				user: 'GET_USUARIO'
			})
		},
		methods: {
			...mapActions('seguridad', {
				doLogin: 'doLogin'
			})
		}
	})
export default class LoginComponent extends Vue {
  private loginData?: ILogin = { numero: '', password: '' }
  // mapGetters TS safe check
  isLoggedIn!: boolean

  // private validNumero = false
  // private validPassword = false
  onReset() {     
     if(this.loginData && this.loginData != null)
     {
       this.loginData.numero = ''
       this.loginData.password = ''
     }
  }

  // onSubmit() {
  //   this.$store.dispatch('seguridad/doLogin', this.loginData).then((response) => {
  //     if(this.isLoggedIn === true) {
  //       this.$router.push({ name: 'home' })
  //     }
  //   });
  // }
  /*
    :invalid-feedback="invalidFeedbackNumero"
    :state="stateNumero"
    valid-feedback="Numero de cuenta válido"
   */
  // NUMERO
  // get stateNumero() {
  //   if(this.numero)
  // }

  // get invalidFeedbackNumero() {
  //   let msg = 'Introduzca un número de empleado válido por favor'
  //   this.validNumero = false
  //   try {
  //     if(this.loginData && this.loginData.numero) {
  //       if(parseInt(this.loginData.numero)) {
  //         this.validNumero = true
  //         msg = 'Debe de ser un número de empleado válido'
  //       } 
  //     } 
  //   }
  //   catch(ex) {      
  //     msg = 'Error al validar el número de empleado'
  //   }

  //   return msg
  // }

}
