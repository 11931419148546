import router from '@/router'
import Axios, { AxiosResponse } from 'axios';
import { ILogin, IUsuario, ISeguridadSate } from '@/store/types/seguridad';
import { IRespuestaApi } from '@/store/types/comun';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

export const seguridadCleanState: ISeguridadSate = {
	error: '',
	isLoggedIn : false,
	usuario : {} as IUsuario,
	token : '',
	permisos :[]
}

@Module({ 
	name: 'seguridad',
	namespaced: true, 
})
export default class Seguridad extends VuexModule {

	local_storage_name = 'bdr-seguridad';

	// siempre tendriamos un data de tipo "IContextoState" para serializar 
	data: ISeguridadSate = {
		error: '',
		isLoggedIn: false,
		usuario: {} as IUsuario,
		token: '',
		permisos: new Array<string>(),
	}

	get GET_DATA(): ISeguridadSate {
		return this.data
	}

	get GET_LOCALSTORAGE_NAME(): string {
		return this.local_storage_name
	}


	get GET_TOKEN(): string {
		return this.data.token || ''
	}

	get GET_USUARIO(): IUsuario {
		return this.data.usuario || {} as IUsuario
	}

	get GET_IS_LOGGED_IN(): boolean {
		return this.data.isLoggedIn || false
	}

	get GET_PERMISOS() {
		return this.data.permisos || []
	}

	get GET_ERROR(): string {
		return this.data.error || ''
	}

	@Mutation
	SET_IS_LOGGED_IN(isLoggedIn: boolean) {
		this.data.isLoggedIn = isLoggedIn
	}

	@Mutation
	SET_USUARIO(user: IUsuario) {
		this.data.usuario = user
	}

	@Mutation
	SET_TOKEN(token: string) {
		this.data.token = token
	}

	@Mutation
	SET_ERROR(error: string) {
		this.data.error = error
	}

	@Mutation
	SET_PERMISOS(permisos: Array<string>) {
		this.data.permisos = permisos
	}

	@Mutation
	SET_DATA(data: ISeguridadSate) {
		this.data = data
	}

	@Action
	async doLoad(){
		const data: ISeguridadSate = JSON.parse(localStorage.getItem(this.context.getters['GET_LOCALSTORAGE_NAME']) || JSON.stringify(seguridadCleanState)) || seguridadCleanState
		
		this.context.commit('SET_DATA', data)		
	}

	@Action
	async doLogin(login: ILogin) {
		this.context.commit('SET_ERROR', '')
		login.mostrar = process.env.VUE_APP_API_USER
		login.revisar = process.env.VUE_APP_API_PWD
		login.token = process.env.VUE_APP_API_TOKEN		

		let response = {} as AxiosResponse

		try{
			response = await Axios.post('public/acceso/login', login)
			if (response && response.data && response.data !== null && response.data.error === false) {
				const respuesta = response.data as IRespuestaApi
				const datos = respuesta.data
				const data = {
					error: '',
					isLoggedIn: true,
					token: datos.token,
					permisos: new Array<string>(),
					usuario: {					
						usr: datos.user,
						numero: datos.numero,
						nombre: datos.nombre,					
						rol: datos.rol, 
						id: datos.id
					} as IUsuario
				} as ISeguridadSate
				this.context.commit('SET_DATA', data)
				localStorage.setItem(this.context.getters['GET_LOCALSTORAGE_NAME'], JSON.stringify(data))
				router.push({ name: 'home' })
			}
			else {
				this.context.commit('SET_ERROR', response.data.mensaje)
			}
		}catch(error) {
			//console.log(error)
			if (error.response) {
				//console.log(error.response)
				this.context.commit('SET_ERROR', error.response.data.mensaje)
			} else {
				this.context.commit('SET_ERROR', 'Error al conectarse al servicio de login.')
			}
		}
	}

	@Action
	async doLogout() {
		//console.log('login out')
		this.context.commit('SET_ERROR', '')
		const user: IUsuario = this.context.getters['GET_USUARIO']
		const parametros = { 'usuario': user.usr }
		Axios.post('private/acceso/logout', parametros);
		this.context.commit('SET_DATA', seguridadCleanState) // no setear a null, porque se pierde la reactividad
		localStorage.removeItem(this.context.getters['GET_LOCALSTORAGE_NAME']) // store the token in localstorage
		router.push({ name: 'login' })		
	}
}