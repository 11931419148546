




import { Component, Vue } from 'vue-property-decorator';
import KardexClienteComponent from '@/components/KardexClienteComponent.vue'; // @ is an alias to /src

@Component({
  components: {
    KardexClienteComponent,
  },
})
export default class KardexCliente extends Vue {}
