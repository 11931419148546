






























































































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { IKardexCliente, ISaldoCliente, IPedidoCliente, IPrecioCliente, IComodatoCliente } from '@/store/types/consultas'
import moment from 'moment'

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
])

@Component({
  computed: {
    ...mapGetters('consultas', {
      kardexCliente: 'GET_CLIENTE',
      saldosCliente: 'GET_SALDOS',
      pedidosCliente: 'GET_PEDIDOS',
      preciosCliente: 'GET_PRECIOS',
      comodatosCliente: 'GET_COMODATOS',
    }),
    
  },  
})
export default class KardexClienteComponent extends Vue {
  private kardexCliente!: IKardexCliente
  private saldosCliente!: Array<ISaldoCliente>
  private pedidosCliente!: Array<IPedidoCliente>
  private preciosCliente!: Array<IPrecioCliente>
  private comodatosCliente!: Array<IComodatoCliente>
  
  totalPrecios = 0.0

  // @Ref('tablaPedidos') readonly tablaPedidos!: any
  // @Ref('tablaSaldos') readonly tablaSaldos!: any
  // @Ref('tablaComodatos') readonly tablaComodatos!: any
  
  fieldsSaldos = [
    {
      key: 'fact',
      label: 'Fact',
      sortable: false
    },
    {
      key: 'fcred',
      label: 'Fecha',
      sortable: true, formatter: "formatDate"
    },
    {
      key: 'dcred',
      label: 'Días cred.',
      sortable: false
    },
    {
      key: 'vence',
      label: 'Venc. días',
      sortable: false
    },
    {
      key: 'fven',
      label: 'Fecha Vencimiento',
      sortable: true, formatter: "formatDate"
    },
    {
      key: 'cred',
      label: 'Credito',
      sortable: false, formatter: "formatCurrency"
    },
    {
      key: 'sal',
      label: 'Saldo',
      sortable: false, formatter: "formatCurrency"
    }
  ]  

  fieldsComodatos = [
    {
      key: 'folio',
      label: 'Folio',
      sortable: false
    },
    {
      key: 'marca',
      label: 'Marca',
      sortable: false
    },
    {
      key: 'modelo',
      label: 'Modelo',
      sortable: false
    },
    {
      key: 'articulo',
      label: 'Articulo',
      sortable: false
    },
    {
      key: 'cantidad',
      label: 'Cantidad',
      sortable: false
    },
    {
      key: 'serie',
      label: 'Serie',
      sortable: false
    }
  ]  
 
  fieldsPedidos = [
    {
      key: 'folio',
      label: 'Folio',
      sortable: false
    },
    {
      key: 'fecha',
      label: 'Fecha',
      sortable: false, formatter: "formatDate"
    },
    {
      key: 'cvepro',
      label: 'SKU',
      sortable: false
    },
    {
      key: 'despro',
      label: 'Presentacion',
      sortable: false
    },
    {
      key: 'cantidad',
      label: 'Cantidad',
      sortable: false
    },
    {
      key: 'precio',
      label: 'Precio',
      sortable: false, formatter: "formatCurrency"
    },
    {
      key: 'total',
      label: 'Total',
      sortable: false, formatter: "formatCurrency"
    }
  ]  

fieldsPrecios = [
    {
      key: 'cvepro',
      label: 'SKU',
      sortable: true
    },
    {
      key: 'etqpro',
      label: 'Etiq.',
      sortable: true
    },
    {
      key: 'despro',
      label: 'Presentacion',
      sortable: true      
    },
    {
      key: 'cantidad',
      label: 'Cantidad',
      sortable: false,
      editable: true
    },
    {
      key: 'precio',
      label: 'Precio',
      sortable: false, formatter: "formatCurrency"
    },
    {
      key: 'total',
      label: 'Total',
      sortable: false, formatter: "formatCurrency"
    }
  ]  

  get cliente(): IKardexCliente {
    return this.kardexCliente
  }

  get saldos(): Array<ISaldoCliente> {
    return this.saldosCliente
  }

  get pedidos(): Array<IPedidoCliente> {
    return this.pedidosCliente
  }

  get comodatos(): Array<IComodatoCliente> {
    return this.comodatosCliente
  }

  get precios(): Array<IPrecioCliente> {
    return this.preciosCliente
  }

  CalcularTotalPrecios() {
    let t = 0.0;
    for(let i = 0; i < this.preciosCliente.length; i++) {
      if(this.preciosCliente[i].cantidad && (this.preciosCliente[i].cantidad || 0) > 0) {
        this.preciosCliente[i].total = (this.preciosCliente[i].cantidad || 0) * (this.preciosCliente[i].precio || 0)
        t += this.preciosCliente[i].total || 0
      }
      
    }
    this.totalPrecios = Math.round(t*100)/100
  }

  formatDate(value?: Date) {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY')
    }
  }

  private formatCurrency(value: number): string {
    if (!value || isNaN(value)) {
        return '';
    }

    const formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2
    });

    return formatter.format(value)
  }
  // The class component now treats beforeRouteEnter,
  // beforeRouteUpdate and beforeRouteLeave as Vue Router hooks
  mounted() {
    const saldo = this.kardexCliente.tel
    this.$store.dispatch('consultas/doGetCliente', this.kardexCliente.cvecli).then(() => {
      if(this.kardexCliente) {
        this.kardexCliente.tel = saldo
      }
    })

    this.$store.dispatch('consultas/doGetComodatosCliente', this.kardexCliente.cvecli)
    this.$store.dispatch('consultas/doGetPedidosCliente', this.kardexCliente.cvecli)
    this.$store.dispatch('consultas/doGetSaldosCliente', this.kardexCliente.cvecli)
    this.$store.dispatch('consultas/doGetPreciosCliente', this.kardexCliente.cvecli)
    
  }

}
