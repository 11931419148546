import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Axios from 'axios'
//console.log(process.env.VUE_APP_API_ENDPOINT);
Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
// Add a request interceptor // TODO Get Token
Axios.interceptors.request.use( (config) => {
	const token = store.getters['seguridad/GET_TOKEN']
	config.headers.common['BDR-TOKEN'] = token;

	return config;	
});


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
