




import { Component, Vue } from 'vue-property-decorator';
import ClientesComponent from '@/components/ClientesComponent.vue'; // @ is an alias to /src

@Component({
  components: {
    ClientesComponent,
  },
})
export default class Clientes extends Vue {}
