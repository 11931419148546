








































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { IUsuario } from '@/store/types/seguridad'
import { ICliente } from '@/store/types/consultas'


@Component({
		computed: {
			...mapGetters('seguridad', {
				isLoggedIn: 'GET_IS_LOGGED_IN',
				user: 'GET_USUARIO'        
			}),
      ...mapGetters('consultas', {
        clientes: 'GET_CLIENTES'
      }),
		},
})
export default class HeaderComponent extends Vue {
  private isLoggedIn?: boolean
  private user?: IUsuario
  private clientes?: Array<ICliente>
  
  searchText = ''
  isSearching = false

  created() {
    // Ya se carga en App.vue
    // pero así evitamos algún race condition
    this.$store.dispatch('seguridad/doLoad')    
	}

  doGetListaClientes() {
    // console.log('buscar cliente con ' + this.searchText )
    this.isSearching = true
    this.$store.dispatch('consultas/doGetListaClientes', this.searchText).then(() => {
      this.isSearching = false
      if (this.clientes && this.clientes.length > 0) {	
        // console.log('ruta:') 
        // console.log(this.$route.name)
        if(this.$route.name !== 'clientes') {
          this.$router.push({ name: 'clientes' })
        }
      }
    }).catch(() => {
      this.isSearching = false
    });

  }

  
}
