

























import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { ICliente, IKardexCliente } from '@/store/types/consultas'


@Component({
  computed: {
    ...mapGetters('consultas', {
      listaClientes: 'GET_CLIENTES'
    })
  },  
})
export default class ClientesComponent extends Vue {
  private listaClientes!: Array<ICliente>
  
  showDetails = false
  
  fields = [
    {
      key: 'cvecli',
      label: 'Clave',
      sortable: false
    },
    {
      key: 'latitud',
      label: 'Ubicacion',
      sortable: false
    },
    {
      key: 'negocio',
      label: 'Negocio',
      sortable: false
    },
    {
      key: 'email',
      label: 'Propietario',
      sortable: false
    },
    {
      key: 'ruta',
      label: 'Ruta',
      sortable: false
    },
    {
      key: 'tel',
      label: 'Saldo Pendiente',
      sortable: false
    }
  ]  

 
  // computed get & set
  get clientes() {
    return this.listaClientes
  }

  verDetalles(item: ICliente) {
    // Establecer el cliente de Kardex primero
    const k: IKardexCliente = {
      cvecli: item.cvecli,
      negocio: item.negocio,
      email: item.email,
      ruta: item.ruta,
      tel: item.tel
    }
    
    this.$store.commit('consultas/SET_CLIENTE', k)

    this.$router.push({ name: 'kardex', params: { cvecli: item.cvecli.toString() }})    
  }

  

}
