




import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';


	@Component({
		computed: {
			...mapGetters('seguridad', {
				isLoggedIn: 'GET_IS_LOGGED_IN',
				error: 'GET_ERROR',
				user: 'GET_USUARIO'
			})
		},
		methods: {
			...mapActions('seguridad', {
				doLogout: 'doLogout'
			})
		}
	})
export default class Logout extends Vue {
  mensaje?: string
  error?: string

  created() {
    this.mensaje = 'Espere un momento mientras se cierra la sesión...'
  }

  mounted() {    
    this.mensaje = 'Cerrando sesión'
    this.$store.dispatch('seguridad/doLogout').then((response) => {
      this.mensaje = this.error
    })
  }
}
